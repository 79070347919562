import React, { useState } from "react";

const GalleryView = ({ galleryItems }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    if (selectedImage === image.fields.file.url) {
      setSelectedImage(null);
    } else {
      setSelectedImage(image.fields.file.url);
    }
  };

  return (
    <div className="GalleryView">
      {galleryItems.map((item) => (
        <div className="GalleryView__item" key={item.sys.id}>
          <img
            src={item.fields.file.url}
            loading="lazy"
            onClick={() => handleImageClick(item)}
          />
          <img
            src={item.fields.file.url}
            loading="lazy"
            onClick={() => handleImageClick(item)}
            className={selectedImage === item.fields.file.url ? "resized" : ""}
          />
        </div>
      ))}
    </div>
  );
};

export default GalleryView;
