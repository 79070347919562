import React, { useEffect, useState } from "react";
import GalleryView_New from "./GalleryView_New";
import { MdClose } from "react-icons/md";

const GalleryOverlayed = ({ eventName, isActiveProp, galleryItems }) => {
  const isMobile = window.innerWidth < 670;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isActiveProp);
  }, [isActiveProp]);

  const handleClick = () => {
    setIsActive(false);
  };

  return (
    <div
      className={`galleryOverlayed ${
        isActive ? "galleryOverlayed--active" : ""
      }`}
    >
      <div className="galleryOverlayed--header">
        <h4>{eventName}</h4>
        <span onClick={handleClick}>
          <MdClose />
        </span>
      </div>
      <div className="gallery__container">
        <GalleryView_New galleryItems={galleryItems} />
      </div>
    </div>
  );
};

export default GalleryOverlayed;
