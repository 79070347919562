import React, { useState, useEffect } from "react";

import { getContentfulEntries } from "../contentful";

const Training = () => {
  const [entries, setEntries] = useState([]);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    getContentfulEntries().then((entries) => {
      let trainings = [];
      entries.items.map((entry) => {
        if (entry.sys.contentType.sys.id === "trainingszeiten") {
          trainings.push(entry.fields);
        }
      });
      // console.log(trainings);
      setTrainings(trainings);
    });
  }, []);

  return (
    <section className="training-section bg--white" id="caipis-training">
      <div className="training-left">
        <div>
          <div className="yellow--dotted--border">
            <h5>Sommertraining</h5>
            <table>
              <thead>
                <tr style={{ borderBottom: "1px solid #fff" }}>
                  <th style={{ paddingLeft: "0" }}>Wochentag</th>
                  <th>Uhrzeit</th>
                  <th>Trainingsort</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{ borderRight: "1px solid #fff", paddingLeft: "0" }}
                  >
                    Mittwoch
                  </td>
                  <td style={{ borderRight: "1px solid #fff" }}>19:00 Uhr</td>
                  <td>
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/LhjT5x5oVM6d2y9EA"
                    >
                      Bootshaus
                    </a>
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #fff" }}>
                  <td
                    style={{ borderRight: "1px solid #fff", paddingLeft: "0" }}
                  >
                    Sonntag
                  </td>
                  <td style={{ borderRight: "1px solid #fff" }}>18:00 Uhr</td>
                  <td>
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/LhjT5x5oVM6d2y9EA"
                    >
                      Bootshaus
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <img
            src="./images/photos/Caipis_in_Aktion_von_der_Seite.jpg"
            alt=""
          />
          <div className="yellow--dotted--border">
            <h5>Wintertraining</h5>
            <table>
              <thead>
                <tr style={{ borderBottom: "1px solid #fff" }}>
                  <th style={{ paddingLeft: "0" }}>Wochentag</th>
                  <th>Uhrzeit</th>
                  <th>Trainingsort</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{ borderRight: "1px solid #fff", paddingLeft: "0" }}
                  >
                    Dienstag
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #fff",
                      whiteSpace: "nowrap",
                    }}
                  >
                    18:15 Uhr
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/tEZ7QqaNQiAbNrfRA"
                    >
                      Sporthalle
                    </a>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ borderRight: "1px solid #fff", paddingLeft: "0" }}
                  >
                    Mittwoch
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #fff",
                      whiteSpace: "nowrap",
                    }}
                  >
                    19:00 Uhr
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/LhjT5x5oVM6d2y9EA"
                    >
                      Bootshaus
                    </a>
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #fff" }}>
                  <td
                    style={{ borderRight: "1px solid #fff", paddingLeft: "0" }}
                  >
                    Sonntag
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #fff",
                      whiteSpace: "nowrap",
                    }}
                  >
                    14:00 Uhr
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/LhjT5x5oVM6d2y9EA"
                    >
                      Bootshaus
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="training-right">
        <div>
          <h2>
            Training – <br />
            Ihr wollt paddeln?
          </h2>
          <h4>
            Kein Problem!
            <br /> Wir freuen uns immer über neue Gesichter im Boot oder in der
            Turnhalle.
          </h4>
          <p>
            Vorkenntnisse braucht ihr keine. Mitbringen solltet ihr
            Sportkleidung die nass werden kann, etwas zu trinken, Duschsachen
            für hinterher und gute Laune. Meldet euch einfach per Mail bei uns,
            dann können wir euch informieren sollte das Training nicht statt
            finden.
          </p>
          <h4>Regelmäßiges Training</h4>
          <p>
            Zwischen unserem Saisonabschluss, dem Isecup, im September und der
            Zeitustellung auf die Winterzeit trainieren wir im Wintermodus. Mit
            Beginn der Sommerzeit, bis zum Isecup trainieren wir im Sommermodus.
            Die jeweiligen Zeiten könnt ihr den unten stehenden Tabellen finden.
            Um fit über den Winter zu kommen haben wir zusätzlich zum
            Wassertraining die Möglichkeit an einem Zirkeltraining für
            Vereinsmitglieder teilzunehmen. Das hält uns fit und man lernt so
            auch die anderen Vereinsmitglieder kennen. Zusätzlich arbeiten wir
            im Winter an unserer Technik.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Training;
