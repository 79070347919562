import React from "react";

const About = () => {
  return (
    <section className="about-section" id="caipis-about">
      <div className="about-left">
        <div>
          <h2>Wir Caipis: Drachenboot fahren im Herzen von Hamburg</h2>
          <h4>
            Das macht uns aus: <br />
            Sport, Teamspirit und Spaß!
          </h4>
          <p>
            Wir sind eine sportlich ambitionierte „Funmannschaft“. Das heißt, im
            Vordergrund steht für uns neben dem gemeinsamen Sport auch immer das
            positive Miteinander. Mit starkem Teamspirit und dem gemeinsamen
            Spaß beim Drachenbootfahren, haben wir schon so manchen Pokal
            gewonnen. Bei Regatten ist es uns besonders wichtig, eine Balance
            aus sportlich herausfordernden und einfach „nur“ schönen Regatten zu
            fahren.
          </p>
          <h4>
            Caipis unterwegs:
            <br />
            Regatten international
          </h4>
          <p>
            Ein besonderes Highlight stellen die Regatten im Ausland dar, auf
            denen wir regelmäßig vertreten sind. Unter anderem haben wir schon
            an der offenen dänischen Meisterschaft teilgenommen, waren auf der
            höchsten Drachenbootregatta der Welt – in Österreich – und haben in
            Amsterdam an einer Regatta mit anschließender Grachtenrundfahrt im
            Drachenboot teilgenommen. Für viele von uns ist die Teilnahme an der
            Vogalonga in Venedig aber die eindrucks- vollste Auslandsregatta
            gewesen.
          </p>
        </div>
      </div>
      <div className="about-right">
        <div>
          <div className="flex-1-1">
            <div>
              <img
                className="graphic_chevron"
                src="./images/photos/Caipis_in_Aktion_von_links.jpg"
                alt=""
              />
            </div>
            <div>
              <h4>
                Unsere Geschichte:
                <br />
                Fast 25 Jahre Caipis
              </h4>
              <p>
                Durch den Zusammenschluss von zwei Drachenboot-Kursen des
                Hochschulsports entstanden wir im Jahr 1999. Seitdem sind wir im
                Training auf den Hamburger Kanälen und auf Regatten im
                norddeutschen Bereich zu finden. Zur Saison 2014 sind wir zu
                unserer ehemaligen Nachbarin, der Wassersportabteilung der
                Sportvereinigung Polizei Hamburg e.V., kurz: WSAP gewechselt.
              </p>
            </div>
          </div>
          <div className="flex-1-1">
            <div>
              <h4 className="graphic_wave">Interessiert?</h4>
              <p>
                Wenn Du Lust bekommen hast das alles selbst einmal zu erleben
                und mit uns im Boot zu sitzen, schreibe uns einfach unter
                Kontakt.
                <br />
                <br />
                Wir freuen uns auf Dich!
              </p>
            </div>
            <div>
              <img src="./images/photos/Caro_im_Boot.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
