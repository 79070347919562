import React, { useState, useEffect } from "react";

import { getContentfulEntries } from "../contentful";

const Start = () => {
  const isMobile = window.innerWidth < 670;
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    getContentfulEntries().then((entries) => setEntries(entries.items));
  }, []);

  return (
    <section className="start-section">
      <div className="text-container">
        <h1>
          <span className="fade-in fade-in--1">Ein Boot</span>
          <br />
          <span className="fade-in fade-in--2">Ein Team</span>
          <br />
          <span className="fade-in fade-in--3">Ein Schlag</span>
        </h1>
        <span className="handwritten handwritten--start fade-in fade-in--4">
          Caipiranhas Eiskalt!
        </span>
      </div>
      <div className="image-container">
        {isMobile ? (
          <img src="./images/photos/Team.jpg" alt="Hero Image" />
        ) : (
          <img src="./images/photos/Titelbild.png" alt="Hero Image" />
        )}
      </div>
    </section>
  );
};

export default Start;
