import React from "react";
import { MdOutlineLocationOn, MdOutlineDrafts } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer-section bg--blue">
      <div>
        <div className="footer-left">
          <h4 className="handwritten">So findest du uns </h4>
          <div>
            <img src="./images/graphics/pin_icon.png" />
            <p>
              Sportvereinigung Polizei Hamburg von 1920 e.V.
              <br />
              Wassersport-Abteilung (WSAP)
              <br />
              Isekai 12
              <br />
              20249 Hamburg
              <br />
            </p>
          </div>
        </div>
        <div className="footer-right">
          <h4 className="handwritten">Du hast eine Frage?</h4>
          <div>
            <img src="./images/graphics/mail_icon.png" />
            <p>
              Dann meld dich gern!
              <br />
              <a href="mailto:tc@caipis-drachenboot.de">
                tc@caipis-drachenboot.de
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
