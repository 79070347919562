import React from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-scroll";

const Teaser = () => {
  return (
    <section
      id="caipis-teaser"
      className="teaser-section bg--white"
      style={{ backgroundImage: "url(./images/graphics/Welle.png)" }}
    >
      <div>
        <Link
          to="caipis-about"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
        >
          <div className="card">
            <img
              src="./images/photos/Caipis_in_Aktion_von_vorne.jpg"
              alt="Image 1"
            />
            <div>
              <h3>Wir Caipis</h3>
              <BsArrowRightCircleFill />
            </div>
          </div>
        </Link>
        <Link
          to="caipis-training"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
        >
          <div className="card">
            <img src="./images/photos/Rico_zeigt_Uebung.jpg" alt="Image 2" />
            <div>
              <h3>Training</h3>
              <BsArrowRightCircleFill />
            </div>
          </div>
        </Link>
        <Link
          to="caipis-photosevents"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
        >
          <div className="card">
            <img src="./images/photos/Bier_am_Steg.jpg" alt="Image 3" />
            <div>
              <h3>Fotos & Events</h3>
              <BsArrowRightCircleFill />
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Teaser;
