import React, { useState, useEffect } from "react";
import Header from "./Sections/Header";
import Start from "./Sections/Start";
import Teaser from "./Sections/Teaser";
import About from "./Sections/About";
import Training from "./Sections/Training";
// import PhotosEvents from "./Sections/PhotosEvents";
import PhotosEvents_New from "./Sections/PhotosEvents_New";
import Contact from "./Sections/Contact";
import Footer from "./Sections/Footer";

import { getContentfulEntries } from "./contentful";

import "./Styles/style.scss";

function App() {
  const isMobile = window.innerWidth < 670;
  const [banner, setBanner] = useState([]);
  const [mBanner, setMBanner] = useState([]);
  const [bannerActive, setBannerActive] = useState([]);

  useEffect(() => {
    getContentfulEntries().then((entries) => {
      // let banner;
      entries.items.map((entry) => {
        if (entry.sys.contentType.sys.id === "banner") {
          console.log(entry.fields.active);
          setMBanner(entry.fields.banner_mobile.fields.file.url);
          setBanner(entry.fields.banner_image.fields.file.url);
          setBannerActive(entry.fields.active);
        }
      });
    });
  }, []);
  return (
    <>
      <Header />
      <main>
        <Start />
        {banner &&
          (bannerActive === "true" || bannerActive === true) &&
          (!isMobile ? (
            <div
              style={{ backgroundColor: "#d4edfc", padding: "80px 0 30px 0" }}
            >
              <img
                src={banner}
                className="divider"
                style={{
                  display: "block",
                  width: "100%",
                  // maxWidth: "1100px",
                  margin: "0 auto",
                }}
              />
            </div>
          ) : (
            <div
              style={{ backgroundColor: "#d4edfc", padding: "20px 0 0px 0" }}
            >
              <img
                src={mBanner}
                className="divider"
                style={{
                  display: "block",
                  width: "100%",
                  margin: "0 auto",
                }}
              />
            </div>
          ))}
        <Teaser />
        <About />
        <Training />
        {/* <PhotosEvents /> */}
        <PhotosEvents_New />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default App;
