import { createClient } from "contentful";

const client = createClient({
  space: "4muxgzgvb17q",
  accessToken: "STEMUt-4TYg7hjraExv1ZvJwUfMo12QVmjyaRuhzBtg",
});

export const getContentfulEntries = async () => {
  const entryItems = await client.getEntries();
  return entryItems;
};
