import React, { useEffect } from "react";
import { BsJustifyLeft, BsInstagram, BsFacebook } from "react-icons/bs";
import { Link } from "react-scroll";

const Header = () => {
  const handleScroll = () => {
    const header = document.querySelector("header");
    const scrollPosition = window.scrollY;

    if (scrollPosition > 20) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header>
        <div className="logo">
          <img src="./images/Caipi_Logo.svg" alt="logo" />
          <span>Caipiranhas Hamburg</span>
        </div>
        <nav>
          <ul>
            <li>
              <Link
                to="caipis-teaser"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <BsJustifyLeft />
              </Link>
            </li>
            <li>
              <Link
                to="caipis-contact"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                {/* <a href="mailto:tc@caipis-drachenboot.de" target="_blank"> */}
                <span>KONTAKT</span>
                {/* </a> */}
              </Link>
            </li>
            <li>
              <a
                href="https://www.instagram.com/wsap_caipiranhas_drachenboot/"
                target="_blank"
              >
                <BsInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/caipis1999/" target="_blank">
                <BsFacebook />
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
