import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import {
  BsArrowRightCircleFill,
  BsCheck,
  BsCheckCircleFill,
} from "react-icons/bs";

function ContactForm() {
  const [state, handleSubmit] = useForm("xoqgoqba");
  if (state.succeeded) {
    return (
      <p className="success-form">
        <BsCheckCircleFill />
        Vielen Dank für Deine Nachricht, wir melden uns bei dir!
      </p>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <input placeholder="E-Mail" id="email" type="email" name="E-Mail" />
      <input placeholder="Dein Name" id="name" type="text" name="Name" />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <textarea
        placeholder="Deine Nachricht an uns"
        id="message"
        name="Nachricht"
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button type="submit" disabled={state.submitting}>
        Absenden
      </button>
    </form>
  );
}

const Contact = () => {
  return (
    <section className="contact-section bg--yellow" id="caipis-contact">
      <div>
        <div className="contact-form">
          <p className="handwritten">Genug von uns</p>
          <h4>Komm ins Boot und erzähl von dir!</h4>
          <ContactForm />
        </div>
        <div className="contact-image">
          <img src="./images/graphics/Kontaktbild.png" alt="Contact" />
        </div>
      </div>
    </section>
  );
};

export default Contact;
